import './App.scss';
import { getRandomQuestions, getRandomLetter } from './utils/questions';
import { Button } from '@material-ui/core';
import { Store } from './Store';

export default function App() {
  const { state: { letters, gameTime } } = React.useContext(Store);
  const [isPaused, setIsPaused] = useState(true);
  // const [numQuestions, setNumQuestions] = useState(12);
  const [questions, setQuestions] = useState(getRandomQuestions(12));
  const [letter, setLetter] = useState(getRandomLetter(letters, ''));
  const [gameTimeRemaining, setGameTimeRemaining] = useState(gameTime);

  useEffect(() => {
    let interval = null;

    if (!isPaused && gameTimeRemaining !== 0) {
      interval = setInterval(() => {
        setGameTimeRemaining(gameTimeRemaining => gameTimeRemaining - 1);
      }, 1000);
    } else if (isPaused) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isPaused, gameTimeRemaining]);

  const handlePauseResume = () => setIsPaused(!isPaused)
  
  const handleNewGame = () => {
    setIsPaused(true);
    setGameTimeRemaining(gameTime);
    setQuestions(getRandomQuestions(12));
    setLetter(getRandomLetter(letters, letter));
  }
  
  const questionList = (
    <div className={isPaused ? 'question-list blurred' : 'question-list'}>
      {questions.map((question, i) => <p key={i}> {i+1}. {question}</p>)}
    </div>
  );

  return (
    <div>
      <div className='game'>
        <div className='game-section mobile'>
          <h2>{letter}</h2>
          <h2>{gameTimeRemaining}</h2>
        </div>
        <div className='game-section'>
          {questionList}
        </div>
        <div className='game-section'>
          <h2>{gameTimeRemaining}</h2>
        </div>
      </div>
      <div className='game-buttons'>
        {gameTimeRemaining !== 0 &&
          <Button variant='outlined' onClick={handlePauseResume}>
            {isPaused ? 'play' : 'pause'}
          </Button>
        }
        <Button variant='outlined' onClick={handleNewGame}>New Game</Button>
      </div>
    </div>
  );
}
 