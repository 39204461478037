import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './components/Header/Header';

export const Store = React.createContext();

const initialState = {
  letters: {
    A: true,
    B: true,
    C: true,
    D: true,
    E: true,
    F: true,
    G: true,
    H: true,
    I: true,
    J: true,
    K: true,
    L: true,
    M: true,
    N: true,
    O: true,
    P: true,
    Q: false,
    R: true,
    S: true,
    T: true,
    U: false,
    V: false,
    W: true,
    X: false,
    Y: false,
    Z: false,
  },
  gameTime: 120,
  numQuestions: 12,
};

function reducer(state, action) {
  switch (action.type) {
  case 'UPDATE_LETTERS':
    return { ...state, letters: action.payload };
  case 'UPDATE_GAME_TIME':
    return { ...state, gameTime: action.payload };
  case 'UPDATE_NUM_QUESTIONS':
    return { ...state, numQuestions: action.payload };
  default:
    return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <Store.Provider value={{ state, dispatch }}>
      <div className='store-provider'>
        <CssBaseline />
        <Header />
        <main className='main'>{props.children}</main>
        <footer className='footer'>Programmer: Alfredo Yebra Jr.</footer>
      </div>
    </Store.Provider>
  );
}
