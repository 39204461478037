import './LettersList.scss';
import { Chip } from '@material-ui/core';

export default function LettersList({ letters, updateLetters }) {
  let temp = [];

  for (let letter in letters) {
    temp.push(
      <Chip
        key={letter}
        className='letters-chip'
        variant='outlined'
        label={letter}
        style={{backgroundColor: letters[letter] ? 'white': '#eee'}}
        onClick={() => updateLetters(letter)}
      />
    );
  }

  return (
    <>
      <div className='letters'>
        <div className='letters-indicators'>
          <Chip
            style={{backgroundColor: 'white', color: 'white'}}
            label='h'
            size='small'
            variant='outlined'
          />
          <div className='indicator-active'>- Active</div>
          <Chip
            style={{backgroundColor: '#eee', color: '#eee'}}
            label='h'
            variant='outlined'
            size='small'
          />
          <div className='indicator'>- Invactive</div>
        </div>
        {temp}
      </div>
    </>
  );
}
