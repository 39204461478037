import React from 'react';

export default function SignUp() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = () => {
    fetch('https://lfg2p2z4mj.execute-api.us-east-1.amazonaws.com/dev/api/users/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        if (data.status === 200) {
          alert('success');
        } else {
          console.log(data);
        }
      })
      .catch(err => { console.log(err)});
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        name='email'
        type='email'
        placeholder='Email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete='user-name'
      />
      <input
        name='password'
        type='password'
        placeholder='Password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete='current-password'
      />
      <button type='submit'>submit</button>
    </form>
  );
}