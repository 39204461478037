import './GameTimeSlider.scss';
import { Slider } from '@material-ui/core';

export default function GameTimeSlider({ gameTime, updateGameTime }) {

  return (
    <div className='game-time-slider-wrap'>
      <h3>Game Time</h3>
      <Slider
        className='game-time-slider'
        defaultValue={120}
        value={gameTime}
        onChange={updateGameTime}
        getAriaValueText={() => `${String(gameTime)}s`}
        aria-labelledby='discrete-slider'
        valueLabelDisplay='auto'
        step={10}
        marks
        min={10}
        max={160}
      />
    </div>
  );
}