import './Header.scss';
import { AppBar, Button, Drawer, IconButton, Toolbar } from '@material-ui/core';
import { Link } from '@reach/router';
import SettingsIcon from '@material-ui/icons/Settings';
import ClearIcon from '@material-ui/icons/Clear';
import Settings from '../Settings/Settings';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <AppBar position='sticky'>
        <Toolbar className='toolbar'>
          <h1 className='header-title'>Scattergories</h1>
          <IconButton onClick={handleOpen}>
            <SettingsIcon style={{color: 'white'}} />
          </IconButton>
          {/* <Button component={Link} to='signup'>
            Sign Up
          </Button>
          <Button component={Link} to='signin'>
            Sign In
          </Button> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant='temporary'
        anchor='top'
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Settings />
        <IconButton onClick={handleClose}>
          <ClearIcon />
        </IconButton>
      </Drawer>
    </>
  );
}