import { Router as AppRouter } from '@reach/router';
import { StoreProvider } from './Store';
import App from './App';
import SignUp from './pages/Signup';

const Router = (
  <StoreProvider>
    <AppRouter primary={false}>
      <App path='/' />
      <SignUp path='signup' />
    </AppRouter>
  </StoreProvider>
);

export default Router;
