import './Settings.scss';
import { Divider } from '@material-ui/core';
import { Store } from '../../Store';
import GameTimeSlider from '../GameTimeSlider/GameTimeSlider';
import LetttersList from '../LettersList/LettersList';

export default function Settings() {
  const { state: { gameTime, letters }, dispatch } = React.useContext(Store);

  const updateLetters = (letter) => {
    letters[letter] = !letters[letter];
    return dispatch({ type: 'UPDATE_LETTERS', payload: letters });
  }

  const updateGameTime = (e, value) => {
    return dispatch({ type: 'UPDATE_GAME_TIME', payload: value });
  }

  return (
    <div className='settings'>
      <div className='settings-section'>
        <LetttersList
          letters={letters}
          updateLetters={updateLetters}
        />
      </div>
      <Divider />
      <div className='settings-section'>
        <GameTimeSlider
          gameTime={gameTime}
          updateGameTime={updateGameTime}
        />
      </div>
      <Divider />
    </div>
  );
}